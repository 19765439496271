$(() => {
    let lang = $('html').attr('lang');

    let convertToAraNum = text => {
        text = text.replace(/0/g, '٠');
        text = text.replace(/1/g, '١');
        text = text.replace(/2/g, '٢');
        text = text.replace(/3/g, '٣');
        text = text.replace(/4/g, '٤');
        text = text.replace(/5/g, '٥');
        text = text.replace(/6/g, '٦');
        text = text.replace(/7/g, '٧');
        text = text.replace(/8/g, '٨');
        text = text.replace(/9/g, '٩');

        return text;
    }

    if (lang === 'ar') {
        $('.hasAraNum').each(function() {
            let originalText = $(this).html();
            $(this).html(convertToAraNum(originalText));
        });
    }

    // toggle password visibility
    $('#toggle-password-visibility').on('click', function() {
        let passwordSelector = $('#password');
        let iconSelector = $('#toggle-password-icon');
        let inputType = (passwordSelector.attr('type')).trim();

        if (inputType === 'password') { // show password
            passwordSelector.attr('type', 'text');
            iconSelector
                .removeClass('bi-eye-slash')
                .addClass('bi-eye');
        } else { // hide password
            passwordSelector.attr('type', 'password');
            iconSelector
                .removeClass('bi-eye')
                .addClass('bi-eye-slash');
        }
    })
})
